var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('c-table',{attrs:{"url":'CustomerWebService/get_customers',"per-page":10,"fields":_vm.columns,"columns":[
          'firstname',
          'lastname',
          'register',
          'phone',
          'email',
          'loan_max_amount',
          'created_date',
          'contracted_date',
          'employerName',
          'employerDistrict',
          'emergency_number',
          'contracted_email',
          'contracted_number',
          'extra_info'
        ],"filename":'Хэрэглэгч',"filters":_vm.filters,"orderDirection":"DESC","orderBy":"created_date","hover":"","bordered":"","fixed":"","caption":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }